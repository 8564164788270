import '@/styles/globals.css';
import type { AppProps } from 'next/app';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import { ApolloProvider } from '@apollo/client';
import client from '@/utils/apollo';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import { gsap } from 'gsap';
import { TransitionProvider } from '@/context/TransitionContext';
import TransitionLayout from '@/components/animation/TransitionLayout';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <StyleSheetManager
        enableVendorPrefixes
        shouldForwardProp={(propName, elementToBeRendered) => {
          return typeof elementToBeRendered === 'string' ? isPropValid(propName) : true;
        }}
      >
        <TransitionProvider>
          <TransitionLayout>
            <ApolloProvider client={client}>
              <Component {...pageProps} />
            </ApolloProvider>
          </TransitionLayout>
        </TransitionProvider>
      </StyleSheetManager>
    </>
  );
}

export default MyApp;
